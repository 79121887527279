import { Container, ContainerSucces } from "./styles";
import { useForm, ValidationError } from "@formspree/react";
import { toast, ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState } from "react";
import validator from "validator";
import axios from "axios";

export function Form() {
  const [state, handleSubmit] = useForm("njn");
  const [s, setS] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [guestName, setGuestName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isHuman, setIsHuman] = useState(false);

  // Email validation function
  function verifyEmail(email: string) {
    if (validator.isEmail(email)) {
      setValidEmail(true);
      setEmail(email);
    } else {
      setValidEmail(false);
    }
  }

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmition = (e: any) => {
    e.preventDefault();
  
    // Prevent multiple submissions by disabling the button while submitting
    if (isSubmitting) return;
  
    setIsSubmitting(true);
  
    // Ensure that the required fields are filled out
    if (validEmail && message && messageTitle && guestName) {
      console.log("Form Data:", {
        email,
        message,
        messageTitle,
        guestName,
        phone,
      });
  
      // Use the API Gateway URL to make the request
      fetch(
        "https://jegq6mxo17.execute-api.us-east-1.amazonaws.com/dev/send-email",  // Your actual API Gateway URL
        {
          method: "POST",
          body: JSON.stringify({
            email,
            message,
            messageTitle,
            guestName,
            phone,
          }),
          headers: {
            "content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            // If the response is not ok, throw an error to handle it in the catch block
            console.error(`HTTP error! status: ${response.status}`);
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json(); // Convert response to JSON
        })
        .then((data) => {
          console.log("Response from API:", data);  // Log the response from the API
          setS(true);  // Set success state
          toast.success("Form submitted successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        })
        .catch((error) => {
          // Log error details for debugging
          console.error("Error during form submission:", error);
          toast.error(`Error submitting the form: ${error.message}`, {
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnFocusLoss: false,
            closeOnClick: true,
            hideProgressBar: false,
            toastId: "error",
          });
        })
        .finally(() => {
          // Re-enable the submit button after submission
          setIsSubmitting(false);
        });
    } else {
      console.warn("Missing required fields"); // Log when required fields are missing
      toast.error("Please fill all the required fields", {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: "error",
      });
      setIsSubmitting(false);
    }
  };
  
  // // Form submission handler
  // const handleSubmition = (e: any) => {
  //   e.preventDefault();
    
  //   // Ensure that the required fields are filled out
  //   if (validEmail && message && messageTitle && guestName) {
      
  //     // Use the API Gateway URL to make the request
  //     fetch(
  //       "https://jegq6mxo17.execute-api.us-east-1.amazonaws.com/dev/contact",  // Your actual API Gateway URL
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           email,
  //           message,
  //           messageTitle,
  //           guestName,
  //           phone
  //         }),
  //         headers: {
  //           "content-type": "application/json",
  //         },
  //       }
  //     ).then(() => {
  //       setS(true);  // Set success state
  //     }).catch((error) => {
  //       toast.error("Error submitting the form", {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //         pauseOnFocusLoss: false,
  //         closeOnClick: true,
  //         hideProgressBar: false,
  //         toastId: "error",
  //       });
  //     });
      
  //   } else {
  //     toast.error("Please fill all the required fields", {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //       pauseOnFocusLoss: false,
  //       closeOnClick: true,
  //       hideProgressBar: false,
  //       toastId: "error",
  //     });
  //   }
  // };
    useEffect(() => {
    if (s) {
      toast.success("Email successfully sent!", {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: "succeeded",
      });
    }
  });

  if (s) {
    return (
      <ContainerSucces>
        <h3>Thanks for getting in touch!</h3>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Back to the top
        </button>
        <ToastContainer />
      </ContainerSucces>
    );
  }

  return (
    <Container>
      <h2>Get in touch using the form</h2>
      <form onSubmit={handleSubmition}>
        <input
          placeholder="Message Title *"
          id="messageTitle"
          name="messageTitle"
          onChange={(e) => setMessageTitle(e.target.value)}
          required
        />
        <input
          placeholder="Guest Name *"
          id="guestName"
          name="guestName"
          onChange={(e) => setGuestName(e.target.value)}
          required
        />
        <input
          placeholder="Email *"
          id="email"
          type="email"
          name="email"
          onChange={(e) => verifyEmail(e.target.value)}
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <input
          placeholder="Phone (Optional)"
          id="phone"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
        />
        <textarea
          required
          placeholder="Message *"
          id="message"
          name="message"
          onChange={(e) => setMessage(e.target.value)}
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        {/* <ReCAPTCHA 
          sitekey="your-site-key"
          onChange={() => setIsHuman(true)}
        /> */}
        <button
          type="submit"
          // disabled={state.submitting || !validEmail || !message || !messageTitle || !guestName || !isHuman}
        >
          Submit
        </button>
      </form>
      <ToastContainer />
    </Container>
  );
}
